import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './components/Home/Home';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import FullCatalogue from './components/FullCatalogue/FullCatalogue';
import ProductPage from './components/ProductPage/ProductPage';
import LoginRegister from './components/LoginRegister/LoginRegister';
import About from './pages/About';
import BreadCrumb from './components/BreadCrumb/BreadCrumb';
import PriceList from './components/PriceList/PriceList';
import MyProfile from './components/MyProfile/MyProfile';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'; // Import the new component
import AboutUs from './components/AboutUs/AboutUs';
import ContactUs from './components/ContactUs/ContactUs';
function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <BreadCrumb />
        
        <div className="Pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/all-products" element={<FullCatalogue />} />
            <Route path="/:product_sku" element={<ProductPage />} />
            <Route path="/login-register" element={<LoginRegister />} />
            <Route path="/about" element={<About />} />
            <Route path="/price-list" element={<PriceList />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/terms-of-use" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/about-us" element={<AboutUs />} /> 
            
<Route path="/contact" element={<ContactUs />} />{/* Add the new route */}
          </Routes>
        </div>
        
        <Footer />
      </div>
    </Router>
  );
}

export default App;
