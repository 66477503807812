import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to HelpTechi! These terms and conditions outline the rules and regulations for the use of HelpTechi's website.
      </p>
      <h2>Acceptance of Terms</h2>
      <p>
        By accessing this website, we assume you accept these terms and conditions. Do not continue to use HelpTechi if you do not agree to all the terms and conditions stated on this page.
      </p>
      <h2>Usage of Our Services</h2>
      <p>
        The content, graphics, and data on this website are for general information purposes only. Unauthorized reproduction, duplication, or misuse is strictly prohibited.
      </p>
      <h2>Additional Disclaimer</h2>
      <p>
        Users using any of HelpTechi service across the following mediums ie. through internet ie www.helptechi.com Website, Wapsite, SMS, phone or any other medium are bound by this additional disclaimer wherein they are cautioned to make proper enquiry before they (Users) rely, act upon or enter into any transaction (any kind or any sort of transaction including but not limited to monetary transaction ) with the Advertiser listed with HelpTechi.
        All the Users are cautioned that all and any information of whatsoever nature provided or received from the Advertiser/s is taken in good faith, without least suspecting the bonafides of the Advertiser/s and HelpTechi does not confirm, does not acknowledge, or subscribe to the claims and representation made by the Advertiser/s listed with HelpTechi .Further, HelpTechi is not at all responsible for any act of Advertiser/s listed at HelpTechi.
      </p>
      <h2>Refund Policy</h2>
      <p>
        Refund Policy is not Applicable
        </p>
      <h2>Terms for UPI-Payments</h2>
      <p>
        HelpTechi is acting as an intermediary between Users and ICICI Bank for providing ICICI UPI facility on its portal.
<br></br>
<br></br>
        These terms and conditions shall be in addition to ICICI Bank's Privacy Policy and HelpTechi’s Privacy Policy and other applicable policy available at HelpTechi portal.
        <br></br>
        <br></br>
        The user of this ICICI Bank UPI facility (the 'User') should have his mobile number registered with the Bank and should have existing relationship with his/her Bank for availing this Facility/Service, using ICICI Bank UPI facility. The Facility/Service shall be made available only to the User satisfying the eligibility criteria and shall be provided at the sole discretion of ICICI Bank Ltd and may be discontinued by ICICI Bank Ltd at any time without notice.
        <br></br>
        <br></br>
        The User shall be solely responsible and liable:
        <br></br>
        <br></br>
        For the accuracy of any personal or other information provided for availing this Facility/Service.
        <br></br>
        <br></br>
        For Pay or Collect requests initiated through ICICI Bank UPI facility.
        <br></br>
        <br></br>
        To comply with the applicable laws, rules and regulations governing such funds transfers as stipulated by the Reserve Bank of India, from time to time.
        <br></br>
        <br></br>
        For all loss, cost and damage, if he/she has breached the terms and conditions contained herein and in the ICICI Bank Mobile Banking Policy.
        <br></br>
        <br></br>
        ICICI and HelpTechi shall not be held responsible and liable for any loss, cost and damage suffered by the User due to disclosure of his personal or other information to a third party including but not limited to statutory/regulatory authority by
        <br></br>
        <br></br>
        ICICI Bank Ltd for whatsoever reason e.g. participation in any telecommunication or electronic clearing network in compliance with a legal or regulatory directive for statistical analysis or for credit rating or for any legal or regulatory compliance.
        <br></br>
        <br></br>
        This services are provided on an "as is, "as available" basis without any representations or warranties. HelpTechi do not represent or warrant that the services will be available, accessible, uninterrupted, timely, secure, accurate, complete, or entirely error -free.
        <br></br>
        <br></br>
        For any concerns for any queries in relation to UPI services please email us at inquiries.solutions@helptechi.com or call us at +91-9467586586 </p>
      <h2>Limitations of Liability</h2>
      <p>
        HelpTechi will not be held responsible for any damages that arise from the use or inability to use our website.
      </p>
      <h2>Governing Law</h2>
      <p>
        These terms and conditions will be governed by and construed in accordance with the laws of your country.
      </p>
      <p className="footer-note">Thank you for choosing HelpTechi!</p>
    </div>
  );
};

export default TermsAndConditions;
