
import React, { useState } from 'react';
import { Row, Col, Carousel, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import Badge from 'react-bootstrap/Badge'

function Section1() {
  const { isLoggedIn } = useGlobalState(); // Check if the user is logged in
  const [showModal, setShowModal] = useState(false); // State to control the modal

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handlePriceListClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      handleShowModal(); // Show the modal
    }
  };

  return (
    <>
      <Row className="section1">
        <Col md={4} className="card">
          <Row className="services">
            <ul className="services-list">
              <li><h2>SERVICES</h2></li>
              <li>
                <Link
                  to="/price-list"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onClick={handlePriceListClick} // Handle click event
                >
                  Price List
                </Link>
              </li>
              <li>Digital Solutions
              <Badge className="badges" pill bg="warning">
                  Coming Soon
                </Badge>
              </li>
              <li>Electronic Parts
              
              </li>
              <li>Electronic Product Services
              <Badge className="badges" pill bg="warning">
                  Coming Soon
                </Badge>
              </li>
            </ul>
          </Row>
        </Col>
        <Col md={8} className="image-carousel">
          {/* <Carousel>
            <Carousel.Item>
              <div className="">
                <img src="https://i.postimg.cc/wxrGqtS4/banner1st-helptechi1.jpg" alt="Carousel" />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="">
                <img src="https://i.postimg.cc/tggJGV2P/file.jpg" alt="Carousel" />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="">
                <img src="https://i.postimg.cc/qvFVghp2/file-1.jpg" alt="Carousel" />
              </div>
            </Carousel.Item>
          </Carousel> */}
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://i.postimg.cc/2yjGTYbt/banner1st-helptechi1-2.jpg"
                alt="First slide"
              />
              <Carousel.Caption>
          
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://i.postimg.cc/ncgK9Bkg/7130939-1.jpg"
                alt="Second slide"
              />
              <Carousel.Caption>
              
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://i.postimg.cc/8CmL5LvL/banner2nd-helptechi-2-1.jpg"
                alt="Third slide"
              />
              <Carousel.Caption>
               
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>

      {/* Modal for login prompt */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please login to view the price list.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Section1;
