import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-header">
        <h1>About Us</h1>
      </div>

      <div className="about-content">
        <p>
          Welcome to <b>HelpTechi</b>, your trusted partner for all tech-related solutions and services. Established with a vision to simplify technology for everyone, we are committed to delivering unparalleled expertise and customer satisfaction.
        </p>

        <h2>Our Mission</h2>
        <p>
          At HelpTechi, our mission is to empower individuals and businesses by providing innovative solutions, expert guidance, and top-notch support for all your technical needs. We aim to bridge the gap between technology and its users, ensuring ease and efficiency in every interaction.
        </p>

        <h2>What We Offer</h2>
        <ul>
          <li>Comprehensive support for electronic devices and appliances.</li>
          <li>Expert advice on troubleshooting and technical queries.</li>
          <li>Professional services tailored to your unique requirements.</li>
          <li>Dedicated customer care to ensure a seamless experience.</li>
        </ul>

        <h2>Why Choose Us?</h2>
        <p>
          With a team of highly skilled professionals and a customer-first approach, HelpTechi stands out as a reliable and innovative leader in the industry. Whether it's a minor issue or a complex challenge, we’re here to assist you every step of the way.
        </p>

        <p>
          Join our community of satisfied customers and experience the difference with HelpTechi. Your tech problems, solved.
        </p>

        <h2>Contact Us</h2>
        <p>
          Have any questions or need assistance? Reach out to us at:
        </p>
        <p>
          <b>Email:</b> inquiries.solutions@helptechi.com<br />
          <b>Phone:</b> 9467-586-586<br />
          <b>Address:</b> <span>Prime Solution Hub,
          Shop No.1, Akshey Apartment Rambaug, Near Vani Vidaylya Kalyan, Thane(421301)</span>
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
