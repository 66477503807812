import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>
        Welcome to Helptechi privacy policy. “Privacy Policy” is engaged in the business of providing technicians with services like price comparison, usage guides, and safe transportation advice for electronic spare parts. It also offers sourcing, community support, and expert assistance for seamless parts management. This Policy outlines our practices in relation to the collection, storage, usage, processing, and disclosure of personal data that you have consented to share with us when you access, use, or otherwise interact with our website available at https://helptechi.com/.We recommend that you read this Privacy Notice in full to ensure you are fully informed.
      </p>

      <h2>1. Collection of Information</h2>
      <p>
        We collect personal information such as name, email, phone number, and address when you interact with our services, including registration, inquiries, or transactions.
      </p>

      <h2>2. Usage of Collected Information</h2>
      <ul>
        <li>To process your requests and transactions efficiently.</li>
        <li>To enhance and personalize your experience on our platform.</li>
        <li>To provide customer support and respond to your inquiries.</li>
        <li>To communicate promotional offers, updates, or service-related announcements.</li>
      </ul>

      <h2>3. Sharing of Information</h2>
      <p>
        We do not share your personal information with third parties without your consent, except as necessary for service provision or legal compliance.
      </p>

      <h2>4. Data Security</h2>
      <p>
        HelpTechi employs robust measures to protect your information from unauthorized access, alteration, or misuse. However, no data transmission over the internet can be guaranteed as fully secure.
      </p>

      <h2>5. Cookies and Tracking</h2>
      <p>
        Our website may use cookies to enhance user experience. Cookies help us analyze web traffic and improve our website to serve you better.
      </p>

      <h2>6. Your Consent</h2>
      <p>
        By using HelpTechi's services, you consent to the collection and use of your information as outlined in this policy. Changes to this policy will be posted here.
      </p>

      <p>
        For any questions or concerns about our Privacy Policy, contact us at <b>inquiries.solutions@helptechi.com</b>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
