import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config'
const BASE_URL = config.baseUrl;
const EditProfile = ({ mobile }) => {
  const [userData, setUserData] = useState(null);

  // Fetch user details when the component mounts
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        let mobile =  localStorage.getItem('userMobile');
        const response = await axios.post(`${BASE_URL}/profile`, { mobile }); // Mobile passed as body
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [mobile]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <Card>
      <Card.Body>
        <Row className="mb-3">
          <Col sm={4}><strong>First Name:</strong></Col>
          <Col sm={8}>{userData.first_name}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4}><strong>Last Name:</strong></Col>
          <Col sm={8}>{userData.last_name}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4}><strong>Shop Name:</strong></Col>
          <Col sm={8}>{userData.shop_name}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4}><strong>GST Number:</strong></Col>
          <Col sm={8}>{userData.gst_num}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4}><strong>Mobile:</strong></Col>
          <Col sm={8}>{userData.mobile}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EditProfile;



// EditProfile.js

// import React, { useEffect, useState } from 'react';
// import { getUserDetails } from '../../api'; // Import the function to call API

// const EditProfile = () => {
//   const [userDetails, setUserDetails] = useState(null);
//   const [loading, setLoading] = useState(true);

//   // Get mobile number from localStorage (or your state management solution)
//   const mobile = localStorage.getItem('userMobile'); // Assuming mobile is stored in localStorage

//   useEffect(() => {
//     if (mobile) {
//       const fetchUserDetails = async () => {
//         try {
//           const response = await getUserDetails(mobile); // Call API to get user details
//           setUserDetails(response.user);
//         } catch (error) {
//           console.error('Error fetching user details:', error);
//         } finally {
//           setLoading(false);
//         }
//       };
//       fetchUserDetails();
//     } else {
//       // Handle case when mobile number is not available (perhaps redirect to login page)
//       console.log('Mobile number not found');
//     }
//   }, [mobile]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!userDetails) {
//     return <div>No user details found</div>;
//   }

//   return (
//     <div>
//       <h2>Edit Profile</h2>
//       <form>
//         <label>
//           First Name:
//           <input type="text" value={userDetails.first_name} readOnly />
//         </label>
//         <label>
//           Last Name:
//           <input type="text" value={userDetails.last_name} readOnly />
//         </label>
//         <label>
//           Shop Name:
//           <input type="text" value={userDetails.shop_name} readOnly />
//         </label>
//         <label>
//           GST Number:
//           <input type="text" value={userDetails.gst_num} readOnly />
//         </label>
//         <label>
//           Mobile:
//           <input type="text" value={userDetails.mobile} readOnly />
//         </label>
//         {/* Add more fields if needed */}
//       </form>
//     </div>
//   );
// };

// export default EditProfile;
