import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../contexts/GlobalStateContext'; // Import GlobalStateContext
import './LoginRegister.css';
import config from '../../config'

function LoginRegister({ closeModal }) {
  const { login } = useGlobalState(); // Access login function from context
  const [isSignup, setIsSignup] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [shopName, setShopName] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [address, setAddress] = useState('');
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const navigate = useNavigate();

const BASE_URL = config.baseUrl;


  // Phone number validation function for India
  const isValidPhoneNumber = (number) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Regex for 10 digit Indian phone numbers starting with 6-9
    return phoneRegex.test(number);
  };

  const handleSendOtp = async () => {
    if (!isValidPhoneNumber(phoneNumber)) {
      alert("Please enter a valid Indian phone number.");
      return;
    }
    try {
      await axios.post(`${BASE_URL}/api/auth/send-otp`, { mobile: phoneNumber });
      setOtpSent(true);
    } catch (error) {
      console.error('Error sending OTP', error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/verify-otp`, { mobile: phoneNumber, otp });
      if (response.data.needsRegistration) {
        setIsSignup(true); // Show registration form
      } else {
        const token = response.data.token;
        const userData = response.data.user;
        localStorage.setItem('userMobile', phoneNumber);  // Store the mobile number in localStorage
        localStorage.setItem('userDetails', JSON.stringify(userData));
        login(userData, token); // Log in the user
        closeModal(); // Close the modal
        navigate('/'); // Redirect to home
      }
    } catch (error) {
      console.error('Error verifying OTP', error);
    }
  };

  const handleRegister = async () => {
    if (firstName && lastName && shopName && gstNumber && address) {
      setIsLoading(true);
      try {
        const response = await axios.post(`${BASE_URL}/api/auth/register`, {
          first_name: firstName,
          last_name: lastName,
          shop_name: shopName,
          gst_num: gstNumber,
          address,
          mobile: phoneNumber,
        });
        
        // Assuming the registration response contains the user data and token
        const { user, token } = response.data;
        login(user, token); // Log in the user after registration
        setRegistrationComplete(true); // Show success message
        closeModal(); // Close the modal
        navigate('/'); // Redirect to home
      } catch (error) {
        console.error('Error registering user', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="auth-box">
      <div className="login-header">
        <div> <a href="https://ibb.co/cXz57tw"><img src="https://i.ibb.co/cXz57tw/file.png" alt="file" border="0"></img></a></div>
        <h2 className="mb-4">Login with OTP</h2>
        <h6>Enter your log in details</h6>
      </div>

      {/* Phone Number Input */}
      <Form.Control
        className="mb-3 input-custom"
        type="text"
        placeholder="Enter Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        readOnly={isSignup} // Make phone number field read-only during registration
        style={{ opacity: isSignup ? 0.5 : 1 }} // Make it appear faded during registration
      />

      {/* OTP input */}
      {otpSent && !isSignup && ( // Only show OTP field if OTP is sent and user is not signing up
        <Form.Control
          className="mb-3 input-custom"
          type="text"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
      )}

      {!otpSent ? (
        <Button variant="primary" className="w-100 btn-custom" onClick={handleSendOtp}>Send OTP</Button>
      ) : isSignup ? (
        <>
          {/* Registration Form Fields */}
          <Form.Control
            className="mb-3 input-custom"
            type="text"
            placeholder="Enter First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Form.Control
            className="mb-3 input-custom"
            type="text"
            placeholder="Enter Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <Form.Control
            className="mb-3 input-custom"
            type="text"
            placeholder="Enter Shop Name"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
          />
          <Form.Control
            className="mb-3 input-custom"
            type="text"
            placeholder="Enter GST Number"
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
          />
          <Form.Control
            className="mb-3 input-custom"
            type="text"
            placeholder="Enter Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <Button
            variant="primary"
            className="w-100 btn-custom"
            type="button"
            onClick={handleRegister}
            disabled={isLoading}
          >
            {isLoading ? 'Registering...' : 'Register'}
          </Button>
        </>
      ) : (
        <Button
          variant="primary"
          className="w-100 btn-custom"
          onClick={handleVerifyOtp}
          disabled={!otp} // Enable only if OTP is provided
        >
          Verify OTP
        </Button>
      )}
    </div>
  );
}

export default LoginRegister;
