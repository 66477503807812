// import React, { useState } from 'react';
// import './ContactUs.css';
// import axios from 'axios';
// import config from '../../config'
// const BASE_URL = config.baseUrl;
// const ContactUs = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     alternate_mobile: '',
//     message: '',
//   });

//   const [status, setStatus] = useState('');
  
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(`${BASE_URL}/contact`, formData);
//       if (response.data.success) {
//         setStatus('Message submitted successfully.');
//         setFormData({ name: '', email: '', alternate_mobile: '', message: '' });
//       }
//     } catch (error) {
//       setStatus('Failed to submit the message. Please try again later.');
//     }
//   };

//   return (
//     <div className="contact-container">
//       <h1>Contact Us</h1>
//       <p>We would love to hear from you. Please fill out the form below and we’ll get in touch soon.</p>
//       <form onSubmit={handleSubmit} className="contact-form">
//         <div className="form-group">
//           <label htmlFor="name">Name:</label>
//           <input
//             type="text"
//             id="name"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="email">Email:</label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="alternate_mobile">Alternate Mobile:</label>
//           <input
//             type="text"
//             id="alternate_mobile"
//             name="alternate_mobile"
//             value={formData.alternate_mobile}
//             onChange={handleChange}
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="message">Message:</label>
//           <textarea
//             id="message"
//             name="message"
//             value={formData.message}
//             onChange={handleChange}
//             required
//           ></textarea>
//         </div>
//         <button type="submit" className="submit-button">Submit</button>
//         {status && <p className="form-status">{status}</p>}
//       </form>
//     </div>
//   );
// };

// export default ContactUs;
import React, { useState, useEffect } from 'react';
import './ContactUs.css';
import axios from 'axios';
import config from '../../config';

const BASE_URL = config.baseUrl;

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    alternate_mobile: '',
    message: '',
  });

  const [status, setStatus] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/contact`, formData);
      console.log("response---",response)
      if (response.data.status == 200) {
        setStatus('Message submitted successfully.');
        setShowPopup(true);
        setFormData({ name: '', email: '', alternate_mobile: '', message: '' });
      } else {
        setStatus('Failed to submit the message. Please try again later.');
      }
    } catch (error) {
      setStatus('Failed to submit the message. Please try again later.');
    }
  };

  useEffect(() => {
    if (showPopup) {
      const timeout = setTimeout(() => {
        setShowPopup(false);
      }, 3000); // Hide the popup after 3 seconds
      return () => clearTimeout(timeout);
    }
  }, [showPopup]);

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <p>We would love to hear from you. Please fill out the form below and we’ll get in touch soon.</p>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="alternate_mobile">Alternate Mobile:</label>
          <input
            type="text"
            id="alternate_mobile"
            name="alternate_mobile"
            value={formData.alternate_mobile}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Query:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-button">Submit Query</button>
        {/* {status && <p className={`form-status ${status.includes('success') ? 'success' : 'error'}`}>{status}</p>} */}
      </form>
      {showPopup && (
        <div className="popup">
          <p>Query submitted successfully</p>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
